import React, { useCallback, useContext } from 'react';
import { InputNumber as InputNumberAnt, Modal } from 'antd';
import { useForm } from '../hooks';
import { z } from 'zod';
import { InputNumber } from './InputNumber';
import { SiparisModel } from '../types';
import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { Select } from './Select';
import { DatePicker } from './DatePicker';

type Props = {
  orderList: SiparisModel[] | undefined;
  open: boolean;
  onOk?: () => void;
  onCancel?: () => void;
};

export const SatisOrderForm = ({ orderList, open, onOk, onCancel }: Props) => {
  const kullaniciModel = useContext(UserContext);

  const [musteriler = []] = API.MUSTERI.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const formKey = orderList?.map((order, index) => ['satisFiyat_' + index, z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' })]) || [];
  const initialKey = orderList?.map((order, index) => ['satisFiyat_' + index, undefined]) || [];

  const Form = z.object({
    ...Object.fromEntries(formKey),
    musteriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
    satisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  });

  const satisForm = useForm(Form, {
    ...Object.fromEntries(initialKey),
    musteriId: undefined,
    satisTarihi: new Date(),
  });

  const satisHandleOk = useCallback(async () => {
    const { data, success } = satisForm.parse();
    if (success) {
      const satisTarihi = new Date(satisForm.value['satisTarihi']);
      satisTarihi.setHours(0);
      satisTarihi.setMinutes(0);
      satisTarihi.setSeconds(0);
      satisTarihi.setMilliseconds(0);

      const satisList = orderList?.map((order, index) => ({
        ...order,
        satisFiyat: satisForm.value['satisFiyat_' + index],
        bhrMusteriModel: {
          musteriId: satisForm.value['musteriId'],
        },
        satisTarihi: satisTarihi.toISOString(),
      })) as SiparisModel[];

      console.log(satisList);
      await API.KOMBI_ORDERS.satis(satisList);

      if (onOk) {
        onOk();
      }
      satisForm.reset();
    } else {
      satisForm.setAllTouched();
    }
  }, [satisForm, orderList, onOk]);

  return (
    <Modal width={300} title="Satış" okText="Kaydet" cancelText="Kapat" open={open} onOk={satisHandleOk} onCancel={onCancel}>
      <div className="flex flex-col">
        {orderList?.map((order, index) => {
          return (
            <div key={order.siparisId} className="flex flex-col">
              <h4>
                {order.bhrUrunModel?.urunAdi} - ₺{order.alisFiyat}
              </h4>
              <label className="whitespace-nowrap">Satış Fiyat :</label>
              <InputNumber form={satisForm} name={'satisFiyat_' + index} min={1} />
            </div>
          );
        })}


<label className="whitespace-nowrap">Satış Tarih :</label>
        <DatePicker form={satisForm} name="satisTarihi" />

        <label className="whitespace-nowrap">Müşteri :</label>
        <Select
          options={musteriler}
          fieldNames={{ label: 'musteriAdi', value: 'musteriId' }}
          searchFields={['musteriAdi']}
          placeholder="Müşteri"
          form={satisForm}
          name="musteriId"
        />

      </div>
    </Modal>
  );
};
