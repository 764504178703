import { useFetch } from '../hooks';
import { AjandaModel, MusteriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<AjandaModel[]>('/bhr/ajanda/list-by-firma-and-ajanda-yil-and-ajanda-ay', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<AjandaModel[]>('/bhr/ajanda/aktif-list-by-firma-and-ajanda-yil-and-ajanda-ay', { method: 'POST', ...config }),
  save: async (product: AjandaModel) => request('/bhr/ajanda/kayit', 'POST', product),
  update: async (product: AjandaModel) => request('/bhr/ajanda/guncelle', 'POST', product),
  activePassive: async (product: AjandaModel) => request('/bhr/ajanda/aktif-pasif', 'POST', product),
};
