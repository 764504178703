import {
  CopyOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EyeOutlined,
  FileExcelOutlined,
  MailOutlined,
  PlusCircleOutlined,
  ProfileOutlined,
  RetweetOutlined,
  RollbackOutlined,
  SendOutlined,
  StepBackwardOutlined,
} from '@ant-design/icons';
import { Button, DatePicker as DatePickerAnt, Input, Modal, Select, Table } from 'antd';
import locale from 'antd/es/date-picker/locale/tr_TR';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import * as API from '../../api';

import { z } from 'zod';
import { DocViewer, InputNumber, Input as InputComp, OrderUpdate, Toolbar, ToolbarLeft, ToolbarRight, SatisOrderForm } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useForm, useOrderList, useWindowSize } from '../../hooks';
import { EVET_HAYIR, ROL, SIPARIS_STATU, SiparisModel } from '../../types';
import { excelExportOrderList } from '../../utils';
import { NewOrder } from './NewOrder';


export const OrderListStep1 = () => {
  const { sm } = useWindowSize();
  const kullaniciModel = useContext(UserContext);

  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const [fillOrder, setFillOrder] = useState<SiparisModel | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [isSatisModalOpen, setIsSatisModalOpen] = useState(false);


  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
    setIsUpdateModalOpen(false);
    setIsSatisModalOpen(false);
  }, [setIsModalOpen]);

  const {
    siparisStatu,
    setSiparisStatu,
    kategori,
    setKategori,
    marka,
    setMarka,
    urun,
    setUrun,
    siparisId,
    setSiparisId,
    siparisTarihi,
    setSiparisTarihi,
    isAdmin,
    list,
    loading,
    getList,
    columns,
    selectedKeyList,
    selectedList,
    onMultiSelectClick,
    pasif,
    satisIptal,
  } = useOrderList();

  const satisHandleOk = useCallback(async () => {
    getList();
    setIsSatisModalOpen(false)
  }, [getList, setIsSatisModalOpen]);


  const excel = useCallback(() => {
    if (list && list.length > 0) {
      excelExportOrderList(list);
    }
  }, [list]);



  const selectedKeyListSt = (selectedKeyList || []) as React.Key[];

  const totalAmount = useMemo(() => {
    if (list && list.length > 0) {
      const amount = list.reduce((acc, item) => (item.alisFiyat ? item.alisFiyat + acc : acc), 0);

      return new Intl.NumberFormat('tr-TR', { style: 'currency', currency: 'TRY', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(amount);
    }
    return '0';
  }, [list]);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarRight classname="sm:flex-none">
          <Button size="small" onClick={() => getList()} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            onClick={() => {
              setFillOrder(null);
              setIsModalOpen(true);
            }}
            icon={<PlusCircleOutlined />}
          >
            Yeni Sipariş
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            disabled={isAdmin ? selectedList?.length !== 1 : selectedList?.length !== 1 || selectedList[0].bhrDepoyeriModel?.tedarikciGorsunMu === 'H'}
            onClick={() => {
              if (selectedList && selectedList.length > 0) {
                setFillOrder(selectedList[0]);
                setIsUpdateModalOpen(true);
              }
            }}
            icon={<EditOutlined />}
          >
            Güncelle
          </Button>
          <Button size="small" className="w-full" disabled={!(list && list.length > 0)} onClick={excel} icon={<FileExcelOutlined />}>
            Excel
          </Button>
          {isAdmin && (
            <>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_DEPO))}
                onClick={() => setIsSatisModalOpen(true)}
                icon={<SendOutlined />}
              >
                Satış
              </Button>
              <Button
                size="small"
                className=" flex-1 sm:flex-none"
                type="primary"
                danger
                disabled={!(selectedList && selectedList.length > 0 && selectedList.every((item) => item.siparisStatu === SIPARIS_STATU.SIP_SATIS))}
                onClick={() => satisIptal(selectedList)}
                icon={<RollbackOutlined />}
              >
                Satış İptal
              </Button>
            </>
          )}
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            danger
            disabled={!Boolean(selectedList?.length)}
            onClick={() => pasif(selectedList)}
            icon={<DeleteOutlined />}
          >
            Sil
          </Button>
        </ToolbarRight>
      </Toolbar>
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <Select
            className="w-full sm:w-64 md:max-w-[100px]"
            showSearch
            placeholder="Statü Seçiniz"
            options={[
              { value: SIPARIS_STATU.SIP_DEPO, label: 'DEPODA' },
              { value: SIPARIS_STATU.SIP_SATIS, label: 'SATILDI' },
            ]}
            fieldNames={{ value: 'value', label: 'label' }}
            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            allowClear
            value={siparisStatu}
            onChange={setSiparisStatu}
          ></Select>


<Select
            className="w-full sm:w-64 md:max-w-[200px]"
            showSearch
            placeholder="Ürün"
            options={products}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            filterOption={(input, option) => [option?.urunAdi ?? '', option?.barkodNo ?? ''].join(' # ').toLowerCase().includes(input.toLowerCase())}
            value={urun}
            onChange={setUrun}
            allowClear
          ></Select>

<Input
            className="w-full sm:w-64 md:max-w-[120px]"
            placeholder="Kayıt No"
            value={siparisId}
            onChange={(e) => setSiparisId(e.target.value as unknown as number)}
            allowClear
          ></Input>

          <DatePickerAnt.RangePicker
            placeholder={['Depo Giriş Tar. Baş.', 'Bitiş Tar.']}
            value={siparisTarihi}
            onChange={(value) => setSiparisTarihi(value as any)}
            allowClear={true}
            className="w-full sm:w-96"
            locale={locale}
            format="DD.MM.YYYY"
          />
        </ToolbarLeft>
      </Toolbar>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
        pagination={{
          defaultPageSize: 25,
          pageSizeOptions: [10, 25, 50, 100, 200],
          position: ['topRight'],
          showTotal: (total) => (
            <div>
              Toplam Kayıt : {total} - Toplam Tutar : {totalAmount}{' '}
            </div>
          ),
        }}
        rowKey="siparisId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={list}
        loading={loading}
        onRow={(record) => ({
          onClick: () => {
            if (isAdmin) {
              const key = record.siparisId as React.Key;
              if (selectedKeyListSt.includes(key)) {
                onMultiSelectClick(selectedKeyListSt.filter((_key) => _key !== key));
              } else {
                onMultiSelectClick([...selectedKeyListSt, key]);
              }
            }
          },
        })}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedKeyList || [],
          onChange: onMultiSelectClick,
        }}
      />

      <Modal width={480} footer={null} cancelText="Kapat" open={isModalOpen} onCancel={handleCancel} bodyStyle={{ marginTop: 20 }}>
        <NewOrder
          key={list?.length}
          fillOrder={fillOrder}
          onSaveDone={() => {
            getList();
            setFillOrder(null);
            onMultiSelectClick([]);
            setIsModalOpen(false);
          }}
        />
      </Modal>
      <Modal width={480} footer={null} cancelText="Kapat" open={isUpdateModalOpen} onCancel={handleCancel} style={{ marginTop: -70 }} bodyStyle={{ marginTop: 20 }}>
        <OrderUpdate
          open={isUpdateModalOpen}
          order={fillOrder}
          onSave={async (order: SiparisModel) => {
            await API.KOMBI_ORDERS.update(order);
            setIsUpdateModalOpen(false);
            setFillOrder(null);
            onMultiSelectClick([]);
            getList();
          }}
        />
      </Modal>
      {isSatisModalOpen && <SatisOrderForm orderList={selectedList} open={isSatisModalOpen} onOk={satisHandleOk} onCancel={handleCancel} />}
    </div>
  );
};

OrderListStep1.path = 'order-list-step-1';
OrderListStep1.title = 'Oluşturulanlar';
OrderListStep1.group = 'supplier';
OrderListStep1.roles = [ROL.TEDARIKCI, ROL.ADMIN];
OrderListStep1.icon = <ProfileOutlined />;
