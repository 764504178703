import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Input, InputNumber as InputNumberAnt } from 'antd';
import { SiparisModel } from '../types';
import uniqby from 'lodash.uniqby';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';

type Props = {
  siparisList: SiparisModel[];
  onChange: CallableFunction;
};

export const OrderSelect = ({ siparisList, onChange }: Props) => {
  const [kapanisAciklama, setKapanisAciklama] = useState('');
  const [search, setSearch] = useState('');
  const [selectedList, setSelectedList] = useState<SiparisModel[]>([]);

  const combinedSiparisList = useMemo(() => {
    let sips = uniqby(siparisList, 'bhrUrunModel.urunId');
    sips = sips.map((sip: SiparisModel) => ({ ...sip, total: siparisList.filter((item) => item.bhrUrunModel?.urunId === sip.bhrUrunModel?.urunId).length }));

    return sips;
  }, [siparisList]);

  const getSiparisByUrun = useCallback(
    (urunId: number) => {
      return siparisList.filter((sip) => sip.bhrUrunModel?.urunId === urunId);
    },
    [siparisList]
  );

  const getSelectedByUrun = useCallback(
    (urunId: number) => {
      return selectedList.filter((sip) => sip.bhrUrunModel?.urunId === urunId);
    },
    [selectedList]
  );

  const add = useCallback(
    (urunId: number) => {
      const siparisList = getSiparisByUrun(urunId);
      const _selectedList = getSelectedByUrun(urunId);

      const next = _selectedList.length;
      let nextSiparis = siparisList[next];

      if(next > 0) {
        nextSiparis = {
          ...nextSiparis,
          satisFiyat : _selectedList[0].satisFiyat
        }
      }

      setSelectedList([...selectedList, nextSiparis]);
    },
    [siparisList, selectedList, getSiparisByUrun, setSelectedList]
  );

  const remove = useCallback(
    (urunId: number) => {
      const last = (selectedList as any).findLast((sip: any) => sip.bhrUrunModel?.urunId === urunId);
      const newList = selectedList.filter((sip) => sip.siparisId !== last.siparisId);

      setSelectedList(newList);
    },
    [siparisList, selectedList, getSiparisByUrun, setSelectedList]
  );

  const getSatisFiyat = useCallback(
    (urunId: number) => {
      const _selectedList = getSelectedByUrun(urunId);

      if(_selectedList.length > 0) {
        return _selectedList[0].satisFiyat;

      }

      return '';
    },
    [siparisList, selectedList, getSiparisByUrun, setSelectedList]
  );

  const setSatisFiyat = useCallback(
    (urunId: number, fiyat: string) => {
      const _fiyat = Number(fiyat) ? Number(fiyat) : 0;
      const fiyatli = selectedList.map((sip) => {
        if(sip.bhrUrunModel?.urunId === urunId) {
          return  {...sip, satisFiyat: _fiyat }
        }
        return {...sip}
      });

      setSelectedList(fiyatli);
    },
    [siparisList, selectedList, getSiparisByUrun, setSelectedList]
  );

  useEffect(() => {
    onChange(selectedList, kapanisAciklama);
  }, [selectedList, onChange, kapanisAciklama]);

  return (
    <div className="flex flex-col w-full">
      <div className="flex items-center gap-1 mb-4">
        <label className="whitespace-nowrap">Kapanış Açıklama :</label>
        <Input value={kapanisAciklama} onChange={(e) => setKapanisAciklama(e.target.value)} />
      </div>
      <Input.Search className="mb-2" value={search} onChange={(e) => setSearch(e.target.value)} />
      <div className="max-h-[60vh] overflow-y-auto overflow-x-hidden pr-1">
        <hr className="w-full" />
        {combinedSiparisList
          .filter((sip: any) => sip.bhrUrunModel?.urunAdi.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
          .map((sip: any) => (
            <Fragment key={sip.siparisId}>
              <div className="flex w-full items-center justify-between ">
                <span>
                  {sip.bhrUrunModel?.urunAdi} ({sip.total})
                </span>
                <div className="flex gap-2 items-center">
                  <Input
                    disabled={getSelectedByUrun(sip.bhrUrunModel?.urunId).length === 0}
                    placeholder="Satış fiyatı"
                    value={getSatisFiyat(sip.bhrUrunModel?.urunId)}
                    onChange={(e) => setSatisFiyat(sip.bhrUrunModel?.urunId, e.target.value)}
                  />
                  <span className="text-lg"> {getSelectedByUrun(sip.bhrUrunModel?.urunId).length}</span>
                  <span>
                    <Button
                      disabled={getSiparisByUrun(sip.bhrUrunModel?.urunId).length === getSelectedByUrun(sip.bhrUrunModel?.urunId).length}
                      onClick={() => add(sip.bhrUrunModel?.urunId)}
                      shape="circle"
                      size="small"
                      icon={<PlusOutlined />}
                    />
                  </span>
                  <Button
                    disabled={getSelectedByUrun(sip.bhrUrunModel?.urunId).length === 0}
                    onClick={() => remove(sip.bhrUrunModel?.urunId)}
                    shape="circle"
                    size="small"
                    icon={<MinusOutlined />}
                  />
                </div>
              </div>
              <hr className="w-full" />
            </Fragment>
          ))}
      </div>

      <div> </div>
    </div>
  );
};
