import { FileOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Collapse, Table, Input as InputAnt, Modal, Button } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useContext, useMemo, useState } from 'react';
import { z } from 'zod';

import * as API from '../../api';
import { DictFormActions, DictTableActions, DictTableStatus, Input, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useOrderList, useWindowSize } from '../../hooks';
import { AKTIF_PASIF, KullaniciModel, MusteriModel, ROL, SIPARIS_STATU, SiparisModel } from '../../types';
import { createOrderColumns } from '../../utils';
import get from 'lodash.get';

const compStr = (keys: string[] | keyof MusteriModel) => (a: MusteriModel, b: MusteriModel) => {
  const aKey = String(get(a, keys));
  const bKey = String(get(b, keys));

  return aKey.localeCompare(bKey);
};

const UserForm = z.object({
  musteriAdi: z.string().min(1, 'Zorunludur'),
  musteriSoyadi: z.string().min(1, 'Zorunludur'),
  telefonNumarasi: z.string().min(1, 'Zorunludur'),
  kombiMarkasi: z.string().nullable().optional(),
  acikAdres: z.string().nullable().optional(),
});

export const Musteri = ({ onlyForm, onSave }: { onlyForm?: boolean; onSave?: CallableFunction }) => {
  const kullaniciModel = useContext(UserContext);
  const isAdmin = kullaniciModel.kullaniciTipi === ROL.ADMIN;

  const { sm, lg } = useWindowSize();
  const { firmaModel } = useContext(UserContext);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [search, setSearch] = useState('');

  const [list = [], loading, getList] = API.MUSTERI.useFetch({ init: true, initBody: firmaModel });
  const { form, selected, clear, fill } = useFillForm(UserForm, {
    musteriAdi: '',
    musteriSoyadi: '',
    telefonNumarasi: '',
    kombiMarkasi: '',
    acikAdres: '',
  });

  const [siparisList, siparisLoading, getSiparisList] = API.KOMBI_ORDERS.useFetch({});

  const columnsSiparis: ColumnsType<SiparisModel> = createOrderColumns(false, false, kullaniciModel.kullaniciTipi === ROL.ADMIN);

  const filteredData = useMemo(() => {
    return list.filter(
      (item) =>
        item.musteriAdi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.musteriSoyadi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.telefonNumarasi?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) ||
        item.acikAdres?.toLocaleLowerCase().includes(search.toLocaleLowerCase()) 
    ) as MusteriModel[];
  }, [list, search]);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      let result: any = null;
      if (selected) {
        result = await API.MUSTERI.update({
          ...data,
          aktifPasif: selected.aktifPasif,
          musteriId: selected.musteriId,
          firmaModel,
        });
      } else {
        result = await API.MUSTERI.save({
          ...data,
          firmaModel,
        });
      }

      if (result.ok) {
        clear();
        getList(firmaModel);

        if (onSave) {
          onSave(data);
        }
      }
    } else {
      form.setAllTouched();
    }
  }, [form, selected]);

  const remove = useCallback(async (values) => {
    await API.MUSTERI.activePassive({
      musteriId: values.musteriId,
      aktifPasif: values.aktifPasif === AKTIF_PASIF.AKTIF ? AKTIF_PASIF.PASIF : AKTIF_PASIF.AKTIF,
    });

    clear();
    getList(firmaModel);
  }, []);

  const columns: ColumnsType<MusteriModel> = [
    {
      title: 'Adı',
      dataIndex: 'musteriAdi',
      key: 'musteriAdi',
      width: 130,
      sorter: compStr('musteriAdi'),
    },
    {
      title: 'Soyad',
      dataIndex: 'musteriSoyadi',
      key: 'musteriSoyadi',
      width: 130,
      sorter: compStr('musteriSoyadi'),
    },
    {
      title: 'Telefon',
      dataIndex: 'telefonNumarasi',
      key: 'telefonNumarasi',
      width: 120,
      sorter: compStr('telefonNumarasi'),
    },
    {
      title: 'Kombi Markası',
      dataIndex: 'kombiMarkasi',
      key: 'kombiMarkasi',
      width: 120,
      sorter: compStr('kombiMarkasi'),
    },
    {
      title: 'Adres',
      dataIndex: 'acikAdres',
      key: 'acikAdres',
      width: 200,
      sorter: compStr('acikAdres'),
    },
    {
      title: 'Durum',
      dataIndex: 'aktifPasif',
      key: 'aktifPasif',
      width: sm ? 100 : 70,
      render: (_: any, record: MusteriModel) => <DictTableStatus record={record} />,
    },
    (lg ? {
      title: 'İşlemler',
      key: 'actions',
      width: sm ? 300 : 120,
      render: (_: any, record: MusteriModel) => (
        <DictTableActions
          record={record}
          remove={remove}
          fill={fill}
          history={() => {
            const filterData: SiparisModel = {
              firmaModel: kullaniciModel.firmaModel,
              siparisStatu: SIPARIS_STATU.SIP_SATIS,
              bhrMusteriModel: {
                musteriId: record.musteriId,
              },
            };

            setIsModalOpen(true);
            getSiparisList(filterData);
          }}
          musteri
        />
      ),
    } : {
      /*title: 'İşlemler',
      key: 'actions',
      width: 120,
      render: (_: any, record: MusteriModel) => (
        <Button block size="small" type="primary" icon={<FileOutlined />} onClick={() => {
          const filterData: SiparisModel = {
            firmaModel: kullaniciModel.firmaModel,
            siparisStatu: SIPARIS_STATU.SIP_SATIS,
            bhrMusteriModel: {
              musteriId: record.musteriId,
            },
          };

          setIsModalOpen(true);
          getSiparisList(filterData);
        }}>
          Geçmiş
        </Button>
      )
*/
    }),
  ].filter(Boolean).filter((item) => item.title);



  if (onlyForm) {
    return (
      <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4">
        <label className="whitespace-nowrap">Ad :</label>
        <Input form={form} name="musteriAdi" />

        <label className="whitespace-nowrap">Soyad :</label>
        <Input form={form} name="musteriSoyadi" />

        <label className="whitespace-nowrap">Telefon :</label>
        <Input form={form} name="telefonNumarasi" />

        <label className="whitespace-nowrap">Kombi Markası :</label>
        <Input form={form} name="kombiMarkasi" />

        <label className="whitespace-nowrap">Adres :</label>
        <Input form={form} name="acikAdres" />

        <DictFormActions selected={selected} save={save} clear={clear} />
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-2">
      <Collapse accordion defaultActiveKey="1">
        <Collapse.Panel header={<span className="text-base font-semibold ">Yeni Müşteri Tanımlama</span>} key="1">
          <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-3xl sm:grid sm:grid-cols-[100px_1fr_100px_1fr] sm:grid-flow-row">
            <label className="whitespace-nowrap">Ad :</label>
            <Input form={form} name="musteriAdi" />

            <label className="whitespace-nowrap">Soyad :</label>
            <Input form={form} name="musteriSoyadi" />

            <label className="whitespace-nowrap">Telefon :</label>
            <Input form={form} name="telefonNumarasi" />

            <label className="whitespace-nowrap">Kombi Markası :</label>
            <Input form={form} name="kombiMarkasi" />

            <label className="whitespace-nowrap">Adres :</label>
            <Input form={form} name="acikAdres" className="sm:col-span-3" classNameInput=" " />

            <div className="col-span-2 sm:col-span-4 flex flex-col sm:flex-row gap-2">
              <DictFormActions selected={selected} save={save} clear={clear} className="sm:justify-end" />
              <InputAnt.Search placeholder="Müşteri Adı, telefeon numarası..." value={search} onChange={(e) => setSearch(e.target.value)} />
            </div>
          </div>
        </Collapse.Panel>
      </Collapse>
      <Table
        scroll={{ y: sm ? 'calc(100svh - 100px)' : 'calc(100svh - 110px)' }}
        pagination={false}
        rowKey="musteriId"
        size="small"
        bordered
        showSorterTooltip={false}
        columns={columns}
        dataSource={filteredData}
        loading={loading}
      />

      <Modal width='96vw' title="Satış" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
        <div className="flex flex-col">
          <Table
            scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100vh - 340px)' }}
            pagination={{
              defaultPageSize: 10,
              pageSizeOptions: [10],
              position: ['topRight'],
              showTotal: (total) => <div>Toplam Kayıt : {total}</div>,
            }}
            rowKey="siparisId"
            size="small"
            bordered
            showSorterTooltip={false}
            columns={columnsSiparis}
            dataSource={siparisList}
            loading={siparisLoading}
          />
        </div>
      </Modal>
    </div>
  );
};

Musteri.path = 'musteri';
Musteri.title = 'Müşteri';
Musteri.group = 'dicts';
Musteri.roles = [ROL.ADMIN];
Musteri.icon = <UsergroupAddOutlined />;
