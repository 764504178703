import { BankOutlined, RetweetOutlined, SendOutlined } from '@ant-design/icons';
import { Button, Input, Modal, Table, Typography } from 'antd';
import React, { useCallback, useContext, useMemo, useState } from 'react';

import { ColumnsType } from 'antd/es/table';
import get from 'lodash.get';
import * as API from '../../api';
import { InputNumber, Toolbar, ToolbarLeft, ToolbarRight } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm, useSingleSelect, useWindowSize } from '../../hooks';
import { BhrStokOzetModel, ROL, SiparisModel } from '../../types';
import { moneyFormat, numberFormat, numberFormatZero } from '../../utils';
import { z } from 'zod';

const { Text } = Typography;

const compNum = (keys: string[] | keyof BhrStokOzetModel) => (a: BhrStokOzetModel, b: BhrStokOzetModel) => {
  const aKey = Number(get(a, keys));
  const bKey = Number(get(b, keys));

  return aKey - bKey;
};

const compStr = (keys: string[] | keyof BhrStokOzetModel) => (a: BhrStokOzetModel, b: BhrStokOzetModel) => {
  const aKey = String(get(a, keys));
  const bKey = String(get(b, keys));

  return aKey.localeCompare(bKey);
};

const compDate = (keys: string[] | keyof BhrStokOzetModel) => (a: BhrStokOzetModel, b: BhrStokOzetModel) => {
  const aKey = new Date(get(a, keys));
  const bKey = new Date(get(b, keys));

  return aKey.getTime() - bKey.getTime();
};

const SatisForm = z.object({
  satisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
});

export const Stok = () => {
  const { sm } = useWindowSize();
  const { firmaModel } = useContext(UserContext);
  const [search, setSearch] = useState('');
  const [selectedStok, setSelectedStok] = useState<BhrStokOzetModel>();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [list = [], loading, getList] = API.DASHBOARD.useFetchStok({ init: true, initBody: firmaModel });

  const filteredData = useMemo(() => {
    return list.filter((item) => item.bhrUrunModel.urunAdi?.toLocaleLowerCase().includes(search) || item.bhrUrunModel.barkodNo?.toLocaleLowerCase().includes(search));
  }, [list, search]);

  const handleCancel = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const { form, clear, fill } = useFillForm(SatisForm, {
    satisFiyat: undefined,
  });

  const satis = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {

      if (selectedStok && selectedStok.stoktakiSiparisIdList && selectedStok.stoktakiSiparisIdList.length > 0) {
        const id = selectedStok.stoktakiSiparisIdList[0];
        const satisTarihi = new Date();
        satisTarihi.setHours(0);
        satisTarihi.setMinutes(0);
        satisTarihi.setSeconds(0);
        satisTarihi.setMilliseconds(0);
        const orders: SiparisModel[] = [
          {
            siparisId: id,
            satisFiyat : data.satisFiyat,
            satisTarihi : satisTarihi.toISOString(),
            bhrMusteriModel: {
              musteriId: 300000,
            },
          },
        ];
        const response = await API.KOMBI_ORDERS.satis(orders);
  
        if (response.ok) {
          getList(firmaModel);
        }
  
        return response;
      } else {
        form.setAllTouched();
      }
    }
  }, [getList, firmaModel, form, selectedStok]);

  const columns: ColumnsType<BhrStokOzetModel> = [
    {
      title: 'Ürün',
      key: 'urunAdi',
      dataIndex: ['bhrUrunModel', 'urunAdi'],
      sorter: compStr(['bhrUrunModel', 'urunAdi']),
      width: 320,
    },
    {
      title: 'Barkod',
      key: 'barkodNo',
      dataIndex: ['bhrUrunModel', 'barkodNo'],
      sorter: compStr(['bhrUrunModel', 'barkodNo']),
    },
    {
      title: 'Stoktaki',
      key: 'stoktaki',
      dataIndex: 'stoktakiAdet',
      align: 'right',
      render: numberFormatZero,
      sorter: compNum('stoktakiAdet'),
    },
    {
      title: 'Ortalama Satış Fiyatı',
      key: 'fiyat',
      dataIndex: ['bhrUrunModel', 'satisFiyat'],
      align: 'right',
      render: moneyFormat,
      sorter: compNum('stoktakiAdet'),
    },
    {
      title: 'İşlemler',
      key: 'actions',
      width: 120,
      render: (_: any, record: BhrStokOzetModel) => (
        <div className="flex flex-col sm:flex-row gap-1">
          <Button
            disabled={!(record.stoktakiSiparisIdList && record.stoktakiSiparisIdList.length > 0)}
            block
            size="small"
            type="primary"
            icon={<SendOutlined />}
            onClick={() => {
              setSelectedStok(record);
              setIsModalOpen(true);
            }}
          >
            Satış
          </Button>
        </div>
      ),
    },
  ];

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarLeft classname="sm:flex-none">
          <Button size="small" onClick={() => getList(firmaModel)} icon={<RetweetOutlined />}>
            Yenile
          </Button>
          <Input.Search value={search} onChange={(e) => setSearch(e.target.value)} />
        </ToolbarLeft>
      </Toolbar>
      <div className="max-w-[1000px]">
        <Table
          scroll={{ y: sm ? 'calc(100svh - 240px)' : 'calc(100svh - 190px)' }}
          pagination={false}
          size="small"
          bordered
          showSorterTooltip={false}
          columns={columns}
          dataSource={filteredData}
          loading={loading}
        />
      </div>

      <Modal width={300} title="Satış" okText="Kaydet" cancelText="Kapat" open={isModalOpen} onOk={satis} onCancel={handleCancel}>
        <div className="flex flex-col">
          <div className="flex flex-col">
            <h4>
              {selectedStok?.bhrUrunModel?.urunAdi} - ₺{selectedStok?.bhrUrunModel?.satisFiyat}
            </h4>
            <label className="whitespace-nowrap">Satış Fiyat :</label>
            <InputNumber form={form} name={'satisFiyat'} min={1} />
          </div>
        </div>
      </Modal>
    </div>
  );
};

Stok.path = 'stok';
Stok.title = 'Stok Özet';
Stok.group = 'supplier';
Stok.roles = [ROL.ADMIN];
Stok.icon = <BankOutlined />;
