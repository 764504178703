import React, { useCallback, useEffect, useMemo } from 'react';

import { App as AppAnt, Layout, Menu, ConfigProvider, Empty, Button } from 'antd';
import trTR from 'antd/locale/tr_TR';
import 'dayjs/locale/tr';
import { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import * as API from './api';
import { Message, RouteBreadcrumb } from './components';
import { TheUser, auth, UserContext } from './contexts/UserContext';
import { useWindowSize } from './hooks';
import * as _pages from './pages';
import { Login } from './pages/Login';
import { KullaniciModel } from './types';
import { pageToMenuItem } from './utils/menu';

const menuGroups = [
  {
    key: 'dicts',
    title: 'Tanım İşlemleri',
  },
  {
    key: 'supplier',
    title: 'Sipariş İşlemleri',
  },
  {
    key: 'bills',
    title: 'Fatura İşlemleri',
  },
];

const pages = Object.entries(_pages);

const menuItems = pages.map(pageToMenuItem);

const { Content, Sider } = Layout;

const imageUrl = new URL('../statics/bhr.jpg', import.meta.url);
const bc = new BroadcastChannel('auth.stok.atlassoft');

export const App = () => {
  const { sm, md } = useWindowSize();
  const [collapsed, setCollapsed] = useState(true);
  const [user, setUser] = useState<KullaniciModel>(TheUser);
  const [openKeys, setOpenKeys] = useState<string[]>([]);

  /*const markets = API.MARKETS.useFetchActive({});
  const categories = API.CATEGORY.useFetchActive({});
  const products = API.PRODUCT.useFetchActive({});
  const warehouses = API.WAREHOUSES.useFetchActive({});*/

  useEffect(() => {
    bc.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        location.reload();
      }
    };
  }, []);

  useEffect(() => {
    if (user && user.kullaniciId) {
      /* markets[2](user.firmaModel);
      categories[2](user.firmaModel);
      products[2](user.firmaModel);
      warehouses[2](user.firmaModel);*/
    }
  }, [user]);

  const logout = useCallback(() => {
    localStorage.clear();
    bc.postMessage({ type: 'LOGOUT' });

    location.reload();
  }, []);

  const { userMenuItems, menu } = useMemo(() => {
    if (user?.kullaniciId) {
      const userMenuItems = menuItems.filter((item) => user.kullaniciTipi && item.roles.includes(user.kullaniciTipi));

      let menu = menuGroups.map((group) => ({
        ...group,
        label: <span>{group.title}</span>,
        children: userMenuItems.filter((item) => item.group === group.key),
      })) as any[];

      menu = menu.filter((item) => item.children.length > 0);

      menu = [...userMenuItems.filter((item) => item.group === 'flat'), ...menu];

      return { userMenuItems, menu };
    }
    return { userMenuItems: [], menu: [] };
  }, [menuItems, user]);

  return (
    <ConfigProvider locale={trTR}  renderEmpty={() => <Empty description="" />}>
      <AppAnt>
        <Message />
        <UserContext.Provider value={user}>
          <BrowserRouter>
            {user?.kullaniciId ? (
              <Layout hasSider>
                <Sider
                  width={sm ? 240 : 'calc(100vw - 60px)'}
                  collapsed={collapsed}
                  breakpoint="md"
                  collapsedWidth="0"
                  zeroWidthTriggerStyle={{ top: 0 }}
                  onCollapse={() => setCollapsed(!collapsed)}
                >
                  <div className="text-lg text-white w-full flex flex-col justify-around items-center py-1">
                    <span>
                      {user.ad} {user.soyad}
                    </span>
                    <Button onClick={logout} ghost className="text-blue-300 font-bold">
                      Çıkış Yap
                    </Button>
                  </div>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginTop: 16,
                      marginBottom: 24,
                    }}
                  >
                    { /*<img src={imageUrl.toString()} style={{ width: '80%', border: '1px solid #888', borderRadius: 3 }} />*/}
                  </div>

                  <Menu
                    theme="dark"
                    mode="inline"
                    items={menu}
                    onClick={() => setCollapsed(!md)}
                    openKeys={openKeys}
                    onOpenChange={(keys) => {
                      setOpenKeys([keys[keys.length - 1]]);
                    }}
                  />

                  {!collapsed && <div className="absolute bottom-10 w-full flex flex-col items-center justify-center font-bold">
                    <span>
                      <a href="tel:+905305401995">+90 530 540 19 95</a>
                    </span>
                    <span className="text-white">Made By Atlas Bilişim © 2023</span>
                  </div>}
                </Sider>
                <Layout style={{ height: '100svh' }}>
                  <RouteBreadcrumb items={userMenuItems} />
                  <Content style={{ margin: '12px 16px 16px', overflow: 'auto' }}>
                    <Routes>
                      {pages.map(([key, Comp]) => (
                        <Route key={key} path={Comp.path} element={<Comp />} />
                      ))}
                    </Routes>
                  </Content>
                </Layout>
              </Layout>
            ) : (
              <Login setUser={setUser} />
            )}
          </BrowserRouter>
        </UserContext.Provider>
      </AppAnt>
    </ConfigProvider>
  );
};
