import { EditOutlined, EyeInvisibleOutlined, EyeOutlined, FileOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

type Props = {
  record?: any;
  fill: CallableFunction;
  remove: CallableFunction;
  history?: CallableFunction;
  musteri?: boolean;
};

export const DictTableActions = ({ record, remove, fill, history, musteri = false }: Props) => {
  return (
    <div className="flex flex-col lg:flex-row gap-1">
      <Button block size="small" type="primary" icon={<EditOutlined />} onClick={() => fill(record)}>
        Düzenle
      </Button>
      <Button block size="small" danger={record.aktifPasif === 'A'} icon={record.aktifPasif === 'A' ? <EyeInvisibleOutlined /> : <EyeOutlined />} onClick={() => remove(record)}>
        {record.aktifPasif === 'A' ? 'Pasif Yap' : 'Aktif Yap'}
      </Button>
      {musteri && (
        <Button block size="small" type="primary" icon={<FileOutlined />} onClick={() => history?.(record)}>
          Geçmiş
        </Button>
      )}
    </div>
  );
};
