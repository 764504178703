import { Button, Drawer, Modal } from 'antd';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { DatePicker, Input, OrderSelect, Select, Toolbar, ToolbarRight } from '../components';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { useFillForm, useWindowSize } from '../hooks';

import { DeleteOutlined, EditOutlined, PlusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import 'dayjs/locale/tr';
import { Calendar, dayjsLocalizer } from 'react-big-calendar';
import { z } from 'zod';
import { AJANDA_STATU, AKTIF_PASIF, AjandaModel, MusteriModel, SIPARIS_STATU, SiparisModel } from '../types';
import { Musteri } from './dicts/Musteri';
import uniqby from 'lodash.uniqby';
dayjs.locale('tr');

const localizer = dayjsLocalizer(dayjs);

const messages = {
  date: 'Tarih',
  time: 'Saat',
  event: 'Randevu',
  allDay: 'Tüm Gün',
  week: 'Hafta',
  work_week: 'Hafta İçi',
  day: 'Gün',
  month: 'Ay',
  previous: 'Geri',
  next: 'İleri',
  yesterday: 'Dün',
  tomorrow: 'Yarın',
  today: 'Bugün',
  agenda: 'Ajanda',

  noEventsInRange: 'Bu aralıkta hiç randevu bulunmuyor.',

  showMore: (total) => `+${total} daha`,
};

const AjandaForm = z.object({
  aciklama: z.string().nullable().optional(),
  tarih: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  ajandaSaatBaslangic: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  ajandaSaatSon: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  musteriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
});

const saatler = Array.from({ length: 24 }, (value, index) => ({ value: index, label: index.toString().padStart(2, '0') + ':00' }));

export const AdminHome = () => {
  const { sm } = useWindowSize();

  const kullaniciModel = useContext(UserContext);
  const [selected, setSelected] = useState<AjandaModel | null>();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [isYeniModalOpen, setIsYeniModalOpen] = useState(false);
  const [isBitirModalOpen, setIsBitirModalOpen] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedSiparisList, setSelectedSiparisList] = useState<SiparisModel[]>([]);
  const [kapanisAciklama, setKapanisAciklama] = useState<string>('');

  const [musteriler = [], _, getMusteriList] = API.MUSTERI.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [siparisList = [], x, _getSiparisList] = API.KOMBI_ORDERS.useFetch({});

  const { form, clear, fill } = useFillForm(AjandaForm, { aciklama: '', tarih: new Date(), ajandaSaatBaslangic: undefined, ajandaSaatSon: undefined, musteriId: undefined });

  const [data = [], loading, _getList] = API.AJANDA.useFetch({
    init: true,
    initBody: { firmaModel: kullaniciModel.firmaModel, ajandaYil: selectedDate.getFullYear(), ajandaAy: selectedDate.getMonth() + 1 },
  });

  const getSiparisList = useCallback(() => {
    const filterData: SiparisModel = {
      firmaModel: kullaniciModel.firmaModel,
      siparisStatu: SIPARIS_STATU.SIP_DEPO,
    };
    _getSiparisList(filterData);
  }, [_getSiparisList]);

  const getList = useCallback(() => {
    setSelected(null);
    _getList({ firmaModel: kullaniciModel.firmaModel, ajandaYil: selectedDate.getFullYear(), ajandaAy: selectedDate.getMonth() + 1 });
  }, [setSelected]);

  const onSelect = useCallback(
    (event: AjandaModel) => {
      setSelected(event);
    },
    [setSelected]
  );

  const handleCancel = useCallback(() => {
    setSelected(null);
    clear();
    setIsYeniModalOpen(false);
    setIsBitirModalOpen(false);
    setSelectedSiparisList([]);
  }, [setIsYeniModalOpen]);

  const bitir = useCallback(async () => {
    if (selected && selectedSiparisList && selectedSiparisList.length > 0) {
      const satisTarihi = new Date(selected.ajandaYil, selected.ajandaAy - 1, selected.ajandaGun);
      satisTarihi.setHours(0);
      satisTarihi.setMinutes(0);
      satisTarihi.setSeconds(0);
      satisTarihi.setMilliseconds(0);
      const updatedList = selectedSiparisList.map((sip) => ({ ...sip, satisTarihi: satisTarihi.toISOString()  , bhrMusteriModel: selected.bhrMusteriModel, bhrAjandaModel: { ...selected, kapanisAciklama } }));
      
      const response = await API.KOMBI_ORDERS.satisWithRandevu(updatedList);
      if (response.ok) {
        getList();
        handleCancel();
      }
    }
  }, [selectedSiparisList, kapanisAciklama]);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const formedData = {
        ...data,
        ajandaYil: data.tarih.getFullYear(),
        ajandaAy: data.tarih.getMonth() + 1,
        ajandaGun: data.tarih.getDate(),
        bhrMusteriModel: {
          musteriId: data.musteriId,
        },
        firmaModel: kullaniciModel.firmaModel,
      } as unknown as AjandaModel;

      await API.AJANDA.save(formedData);

      getList();
      handleCancel();
    } else {
      form.setAllTouched();
    }
  }, [form]);

  const remove = useCallback(async () => {
    if (selected) {
      await API.AJANDA.activePassive({
        ...selected,
        aktifPasif: AKTIF_PASIF.PASIF,
      });

      getList();
    }
  }, [selected]);

  const eventPropGetter = useCallback(
    (data: AjandaModel) => {
      const selectedClass = selected?.ajandaId === data.ajandaId ? ' !outline-4 !outline-orange-500/80 !outline !outline-offset-[-2px]' : '';
      if (data.ajandaStatu === AJANDA_STATU.AJN_IPTAL) {
        return {
          className: '!bg-red-500' + selectedClass,
        };
      } else if (data.ajandaStatu === AJANDA_STATU.AJN_KAPANDI) {
        return {
          className: '!bg-green-500' + selectedClass,
        };
      } else if (data.ajandaStatu === AJANDA_STATU.AJN_OLUSTU) {
        return {
          className: '!bg-blue-500' + selectedClass,
        };
      }
    },
    [selected]
  );

  const events = useMemo(() => {
    return data?.map((item) => ({
      ...item,
      id: item.ajandaId,
      title: item.bhrMusteriModel.musteriAdi + ' ' + item.bhrMusteriModel.musteriSoyadi + ' \n' + (item.aciklama || '') + ' \n ' + (item.kapanisAciklama || ''),
      start: new Date(item.ajandaYil, item.ajandaAy - 1, item.ajandaGun, item.ajandaSaatBaslangic),
      end: new Date(item.ajandaYil, item.ajandaAy - 1, item.ajandaGun, item.ajandaSaatSon),
    }));
  }, [data]);

  const defaultDate = useMemo(() => {
    return new Date();
  }, [data]);

  useEffect(() => {
    getSiparisList();
  }, []);

  return (
    <div className="flex flex-col gap-2">
      <Toolbar>
        <ToolbarRight classname="sm:flex-none">
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            onClick={() => {
              setSelected(null);
              clear();
              setIsYeniModalOpen(true);
            }}
            icon={<PlusCircleOutlined />}
          >
            Yeni Randevu
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            disabled={!Boolean(selected) || selected?.ajandaStatu !== AJANDA_STATU.AJN_OLUSTU}
            onClick={() => {
              setSelectedSiparisList([]);
              setIsBitirModalOpen(true);
            }}
            icon={<PlusCircleOutlined />}
          >
            Bitir
          </Button>
          <Button
            size="small"
            className=" flex-1 sm:flex-none"
            type="primary"
            danger
            disabled={!Boolean(selected) || selected?.ajandaStatu !== AJANDA_STATU.AJN_OLUSTU}
            onClick={remove}
            icon={<DeleteOutlined />}
          >
            Sil
          </Button>
          {selected && (
            <div>
              {selected.bhrMusteriModel.musteriAdi} {selected.bhrMusteriModel.musteriSoyadi} - {selected.bhrMusteriModel.telefonNumarasi} - {selected.bhrMusteriModel.acikAdres}
            </div>
          )}
        </ToolbarRight>
      </Toolbar>
      <div className="relative h-[90vh]">
        <Calendar
          events={events}
          localizer={localizer}
          defaultDate={defaultDate}
          defaultView={'week'}
          messages={messages}
          showMultiDayTimes
          step={30}
          dayLayoutAlgorithm="no-overlap"
          selected={selected}
          onSelectEvent={onSelect}
          date={selectedDate}
          onNavigate={setSelectedDate}
          eventPropGetter={eventPropGetter}
        />
      </div>

      <Modal width={500} title="Yeni Randevu" okText="Kaydet" cancelText="Kapat" open={isYeniModalOpen} onOk={save} onCancel={handleCancel}>
        <div className="grid grid-cols-[100px_1fr_20px] items-center gap-y-6 gap-x-4">
          <label className="whitespace-nowrap">Tarih :</label>
          <DatePicker form={form} name="tarih" />
          <span></span>

          <label className="whitespace-nowrap">Başlangıç Saati :</label>
          <Select options={saatler} fieldNames={{ label: 'label', value: 'value' }} form={form} name="ajandaSaatBaslangic" />
          <span></span>

          <label className="whitespace-nowrap">Bitiş Saati :</label>
          <Select options={saatler} fieldNames={{ label: 'label', value: 'value' }} form={form} name="ajandaSaatSon" />
          <span></span>

          <label className="whitespace-nowrap">Müşteri :</label>
          <Select
            options={musteriler}
            fieldNames={{ label: 'musteriAdi', value: 'musteriId' }}
            searchFields={['musteriAdi', 'telefonNumarasi']}
            placeholder="Müşteri"
            form={form}
            name="musteriId"
          />
          <Button type="primary" shape="circle" icon={<PlusOutlined />} onClick={() => setDrawerOpen(true)} />

          <label className="whitespace-nowrap">Açıklama :</label>
          <Input form={form} name="aciklama" />
          <span></span>
        </div>
      </Modal>

      <Modal
        width={600}
        title={`Randevu Bitirme : ${selected?.bhrMusteriModel.musteriAdi} ${selected?.bhrMusteriModel.musteriSoyadi}`}
        okText="Kaydet"
        cancelText="Kapat"
        open={isBitirModalOpen}
        onOk={bitir}
        onCancel={handleCancel}
      >
        <div className="flex flex-col items-start gap-y-6 gap-x-4">
          <OrderSelect
            siparisList={siparisList}
            onChange={(list: any, aciklama: any) => {
              setSelectedSiparisList(list);
              setKapanisAciklama(aciklama);
            }}
          />
        </div>
      </Modal>

      <Drawer title="Yeni Müşteri Kayıt" placement="right" onClose={() => setDrawerOpen(false)} open={drawerOpen}>
        <Musteri
          onlyForm
          onSave={async (musteri: MusteriModel) => {
            setDrawerOpen(false);

            const list = await getMusteriList(kullaniciModel.firmaModel);
            const found = list.find((item) => item.telefonNumarasi === musteri.telefonNumarasi);
            if (found) {
              fill({ ...form.value, musteriId: found.musteriId });
            }
          }}
        />
      </Drawer>
    </div>
  );
};
