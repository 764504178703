import { useFetch } from '../hooks';
import { MusteriModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<MusteriModel[]>('/bhr/musteri/list-by-firma', { method: 'POST', ...config }),
  useFetchActive: (config: UseFetchOptions) => useFetch<MusteriModel[]>('/bhr/musteri/aktif-list-by-firma', { method: 'POST', ...config }),
  save: async (product: MusteriModel) => request('/bhr/musteri/kayit', 'POST', product),
  update: async (product: MusteriModel) => request('/bhr/musteri/guncelle', 'POST', product),
  activePassive: async (product: MusteriModel) => request('/bhr/musteri/aktif-pasif', 'POST', product),
};
