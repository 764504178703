import { useFetch } from '../hooks';
import { SiparisModel, UseFetchOptions } from '../types';
import { request } from '../utils';

export default {
  useFetch: (config: UseFetchOptions) => useFetch<SiparisModel[]>('/bhr/kombi-siparis/list', { method: 'POST', ...config }),
  pasif: async (order: SiparisModel[]) => request('/bhr/kombi-siparis/pasif', 'POST', order),
  satis: async (order: SiparisModel[]) => request('/bhr/kombi-siparis/satis', 'POST', order),
  satisWithRandevu: async (order: SiparisModel[]) => request('/bhr/kombi-siparis/satis-with-randevu', 'POST', order),
  satisIptal: async (order: SiparisModel[]) => request('/bhr/kombi-siparis/satis-iptal', 'POST', order),
  save: async (order: SiparisModel[]) => request('/bhr/kombi-siparis/kayit', 'POST', order),
  update: async (order: SiparisModel) => request('/bhr/kombi-siparis/guncelle', 'POST', order),
};
