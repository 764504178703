import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { message } from 'antd';

import * as API from '../api';
import { UserContext } from '../contexts/UserContext';
import { useFillForm } from '../hooks';
import { ROL, SIPARIS_STATU, SiparisModel } from '../types';
import { Select } from './Select';
import { Input } from './Input';
import { InputNumber } from './InputNumber';
import { DatePicker } from './DatePicker';
import { DictFormActions } from './DictFormActions';

const OrderForm = z.object({
  kategoriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  markaId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  alisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
  seriNo: z.string().min(1, 'Zorunludur'),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  satisFiyat: z.number().nullable().optional(),
  satisTarihi: z.date().nullable().optional(),
  musteriId: z.number().nullable().optional(),
});

export const OrderUpdate = ({ onSave = (order) => null, order, open }: { onSave: (order: SiparisModel) => void; order: SiparisModel | null, open : boolean }) => {
  const kullaniciModel = useContext(UserContext);

  const [musteriler = []] = API.MUSTERI.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });


  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear, fill } = useFillForm(OrderForm, {
    markaId: undefined,
    kategoriId: undefined,
    urunId: undefined,
    alisFiyat: undefined,
    seriNo : '',
    siparisTarihi: new Date(),
    satisFiyat: undefined,
    satisTarihi: new Date(),
    musteriId: undefined,

  });


  useEffect(() => {
    clear();
    if (order) {
      fill({
        kategoriId: order.bhrKategoriModel?.kategoriId,
        markaId: order.bhrMarkaModel?.markaId,
        urunId: order.bhrUrunModel?.urunId,
        alisFiyat: order.alisFiyat,
        seriNo: order.seriNo,
        siparisTarihi: order.siparisTarihi ? new Date(order.siparisTarihi) : new Date(),
        musteriId: order.bhrMusteriModel?.musteriId,
        satisFiyat: order.satisFiyat,
        satisTarihi: order.satisTarihi ? new Date(order.satisTarihi) : new Date(),
      });
    }
  }, [order, open]);


  const productByCategory = products.filter((product) => product.bhrKategoriModel?.kategoriId === form.value.kategoriId && product.bhrMarkaModel?.markaId === form.value.markaId);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {

      const siparisTarihi = new Date(data.siparisTarihi);
      siparisTarihi.setHours(0);
      siparisTarihi.setMinutes(0);
      siparisTarihi.setSeconds(0);
      siparisTarihi.setMilliseconds(0);

      const satisTarihi = new Date(data.satisTarihi);
      satisTarihi.setHours(0);
      satisTarihi.setMinutes(0);
      satisTarihi.setSeconds(0);
      satisTarihi.setMilliseconds(0);

      const formedData = {
        ...order,
        bhrKategoriModel: {
          kategoriId: data.kategoriId,
        },
        bhrMarkaModel: {
          markaId: data.markaId,
        },
        bhrUrunModel: {
          urunId: data.urunId,
        },
        alisFiyat: data.alisFiyat,
        seriNo: data.seriNo,
        siparisTarihi: siparisTarihi.toISOString(),
        satisFiyat: data.satisFiyat,
        satisTarihi: satisTarihi.toISOString(),
        bhrMusteriModel : data.musteriId ? {
          musteriId : data.musteriId,
        } : order?.bhrMusteriModel,
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: kullaniciModel.kullaniciId,
        },
      };

      onSave(formedData);
      clear();
    } else {
      form.setAllTouched();
    }
  }, [form, order]);

  return (
    <div className="flex flex-col gap-2">
      <Card title="Sipariş Güncelleme">
        <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
          <label className="whitespace-nowrap">Kategori :</label>
          <Select options={categories} fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }} form={form} name="kategoriId" />

          <label className="whitespace-nowrap">Marka :</label>
          <Select options={brands} fieldNames={{ label: 'markaAdi', value: 'markaId' }} form={form} name="markaId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            searchFields={['barkodNo']}
            placeholder="Ürün Adı veya Barkod"
            form={form}
            name="urunId"
          />

          <label className="whitespace-nowrap">Seri No :</label>
              <Input form={form} name={'seriNo'} />

          <label className="whitespace-nowrap">Alış Fiyat :</label>
          <InputNumber form={form} name="alisFiyat" min={0} />

          <label className="whitespace-nowrap">Depo Giriş Tarih :</label>
          <DatePicker form={form} name="siparisTarihi" />

          <label className="whitespace-nowrap">Satış Fiyat :</label>
          <InputNumber form={form} name="satisFiyat" min={0}  disabled={order?.siparisStatu === SIPARIS_STATU.SIP_DEPO} />

          <label className="whitespace-nowrap">Satış Tarih :</label>
          <DatePicker form={form} name="satisTarihi"  disabled={order?.siparisStatu === SIPARIS_STATU.SIP_DEPO} />


          <label className="whitespace-nowrap">Müşteri :</label>
          <Select
            options={musteriler}
            fieldNames={{ label: 'musteriAdi', value: 'musteriId' }}
            searchFields={['musteriAdi']}
            placeholder="Müşteri"
            form={form}
            name="musteriId"
            disabled={order?.siparisStatu === SIPARIS_STATU.SIP_DEPO}
          />

          <DictFormActions save={save} clear={clear} />
        </div>
      </Card>
    </div>
  );
}
