import React, { useCallback } from 'react';
import { Input as InputAnt } from 'antd';

type Props = {
  form: any;
  name: string;
  disabled?: boolean;
  ascii?: boolean;
  className?: string;
  classNameInput?: string;
};

export const Input = ({ form, name, disabled, ascii, className, classNameInput }: Props) => {
  const onKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (ascii && !/[a-zA-Z0-9_]/.test(event.key)) {
        event.preventDefault();
      }
    },
    [ascii]
  );

  const _className = className ? className :  'relative';
  const _classNameInput = classNameInput ? classNameInput :  'md:max-w-[300px]';

  return (
    <div className={_className}>
      <InputAnt className={_classNameInput} disabled={disabled} {...form.props(name)} onKeyDown={onKeyDown} onChange={(e) => form.setField(name)(e.target.value)} />
      {form.toucheds[name] && <small className="left-0 -bottom-4 text-red-500">{form.errors[name]}</small>}
    </div>
  );
};
