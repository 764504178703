import { PlusCircleOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import React, { Fragment, useCallback, useContext, useEffect, useMemo } from 'react';
import { z } from 'zod';
import { message } from 'antd';

import * as API from '../../api';
import { DatePicker, DictFormActions, Input, InputNumber, Select } from '../../components';
import { UserContext } from '../../contexts/UserContext';
import { useFillForm } from '../../hooks';
import { ROL, SiparisModel } from '../../types';

const OrderForm = z.object({
  kategoriId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  markaId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  urunId: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  alisFiyat: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).gt(0, "Fiyat 0'dan büyük olmalı."),
  siparisTarihi: z.date({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }),
  adet: z.number({ required_error: 'Zorunludur', invalid_type_error: 'Zorunludur' }).min(1, "Adet 1'den büyük olmalı."),
});

export const NewOrder = ({ onSaveDone = () => null, fillOrder }: { onSaveDone?: CallableFunction; fillOrder?: SiparisModel | null }) => {
  const kullaniciModel = useContext(UserContext);

  const [categories = []] = API.CATEGORY.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [brands = []] = API.BRAND.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });
  const [products = []] = API.PRODUCT.useFetchActive({ init: true, initBody: kullaniciModel.firmaModel });

  const { form, clear, fill } = useFillForm(OrderForm, {
    markaId: undefined,
    kategoriId: undefined,
    urunId: undefined,
    alisFiyat: undefined,
    siparisTarihi: new Date(),
    adet: 1,
  });


  const productByCategory = products?.filter((product) => product.bhrKategoriModel?.kategoriId === form.value.kategoriId && product.bhrMarkaModel?.markaId === form.value.markaId);

  const save = useCallback(async () => {
    const { success, data } = form.parse();
    if (success) {
      const siparisTarihi = new Date(data.siparisTarihi);
      siparisTarihi.setHours(0);
      siparisTarihi.setMinutes(0);
      siparisTarihi.setSeconds(0);
      siparisTarihi.setMilliseconds(0);
      const formedData = {
        bhrKategoriModel: {
          kategoriId: data.kategoriId,
        },
        bhrMarkaModel: {
          markaId: data.markaId,
        },
        bhrUrunModel: {
          urunId: data.urunId,
        },
        alisFiyat: data.alisFiyat,
        adet: data.adet,
        siparisTarihi: siparisTarihi.toISOString(),
        firmaModel: kullaniciModel.firmaModel,
        kullaniciModel: {
          kullaniciId: kullaniciModel.kullaniciId,
        },
      };

      const saveArray: SiparisModel[] = [];
      for (let i = 0; i < Number(data.adet); i++) {
        const saveData = {
          ...formedData,
        };

        saveArray.push(saveData);
      }

      await API.KOMBI_ORDERS.save(saveArray);

      onSaveDone();
      clear();
    } else {
      form.setAllTouched();
    }
  }, [form]);

  return (
    <div className="flex flex-col gap-2">
      <Card title="Depo Giriş">
        <div className="grid grid-cols-[100px_1fr] items-center gap-y-6 gap-x-4 sm:max-w-sm ">
          <label className="whitespace-nowrap">Kategori :</label>
          <Select options={categories} fieldNames={{ label: 'kategoriAdi', value: 'kategoriId' }} form={form} name="kategoriId" />

          <label className="whitespace-nowrap">Marka :</label>
          <Select options={brands} fieldNames={{ label: 'markaAdi', value: 'markaId' }} form={form} name="markaId" />

          <label className="whitespace-nowrap">Ürün :</label>
          <Select
            options={productByCategory}
            fieldNames={{ label: 'urunAdi', value: 'urunId' }}
            searchFields={['barkodNo']}
            placeholder="Ürün Adı veya Barkod"
            form={form}
            name="urunId"
          />

          <label className="whitespace-nowrap">Adet :</label>
          <InputNumber form={form} name="adet" min={1} />

          <label className="whitespace-nowrap">Alış Fiyat :</label>
          <InputNumber form={form} name="alisFiyat" min={0} />

          <label className="whitespace-nowrap">Depo Giriş Tarih :</label>
          <DatePicker form={form} name="siparisTarihi" />

          <DictFormActions save={save} clear={clear} />
        </div>
      </Card>
    </div>
  );
};

NewOrder.path = 'new-order';
NewOrder.title = 'Depo Giriş';
NewOrder.group = 'supplier';
NewOrder.roles = [ROL.TEDARIKCI, ROL.ADMIN];
NewOrder.icon = <PlusCircleOutlined />;
